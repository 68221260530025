import { render, staticRenderFns } from "./wares-route.vue?vue&type=template&id=531a1112&scoped=true"
import script from "./wares-route.vue?vue&type=script&lang=js"
export * from "./wares-route.vue?vue&type=script&lang=js"
import style0 from "./wares-route.vue?vue&type=style&index=0&id=531a1112&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531a1112",
  null
  
)

export default component.exports